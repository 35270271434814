<template>
  <div class="user-cards">
    <div v-for="organizer in users" :key="organizer.id" @click="goTo(organizer.link)" style="cursor: pointer">
      <div class="card">
        <div class="avatar">
          <img :src="organizer.avatar" alt="user avatar">
        </div>
        <div class="username">
          {{ organizer.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import members from "@/assets/members.json"
export default {
  data() {
    return {

    };
  },
  props:{
    users: Array
  },
  mounted() {

  },
  methods: {
    goTo(link){
      window.open(link, '_blank');
    }
  }
};
</script>

<style scoped>
.user-cards{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px
}

.card{
  width: 140px;
  height: 54px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-right: 6px;
  margin-bottom: 10px;
  background-color: #373737;
}

.avatar{
  height: 46px;
  margin: 0 3px 0 4px;
}

.avatar img {
  width: 46px; /* 正方形头像，宽度和高度相同 */
  height: 46px;
  object-fit: scale-down;
  border-radius: 15%; /* 圆形头像 */
}

.username {
  text-align: center;
  font-size: 14px;
  margin-left: 3px;
}
</style>