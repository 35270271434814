import Vue from 'vue'
import VueRouter from "vue-router"
Vue.use(VueRouter)

import home from "../views/home.vue"
import tournament from "../views/tournament.vue"
import frame from "../views/frame.vue"
import about from "../views/about.vue"
import search from "../views/search.vue"
import event from "@/views/event.vue"

const router = new VueRouter(
    {
        mode: "history",
        base: process.env.BASE_URL,
        routes: [
            {
                path:"/",
                redirect:"/home",
                component:frame,
                children: [
                    {
                        path:"/home",
                        component:() => import("@/views/home.vue"),
                    },
                    {
                        path:"/tournament",
                        component:() => import("@/views/tournament.vue"),
                    },
                    {
                        path:"/about",
                        component:() => import("@/views/about.vue"),
                    },
                    {
                        path:"/search",
                        component:() => import("@/views/search.vue"),
                    },
                    {
                        path:"/event/:id",
                        component:() => import("@/views/event.vue"),
                    },
                    {
                        path:"/mdEditor",
                        component:() => import("@/views/mdEditor.vue"),
                    }
                ]
            },
        ]
    }
)

//重写路由方法
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err);
};

export default router
