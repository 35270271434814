<template>
  <el-backtop style="background-color: #e1e1e1">
    <div
        style="{
          height: 100%;
          width: 100%;
          background-color: #e1e1e1;
          text-align: center;
          border-radius: 50%;
          line-height: 40px;
          color: #1e1e1e;
        }">
      ▲
    </div>
  </el-backtop>
</template>

<script>
export default {
  name: "backtop"
}
</script>

<style scoped>

</style>