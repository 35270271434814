<template>
  <div>
    <el-row style="padding-top: 2vh" type="flex" justify="center">
      <el-col :lg="14" :xs="24" :sm="24" :md="24">
        <div>
          <el-card :body-style="{ padding: '0px', background: '#1e1e1e'}">
            <div class="card-body">
              <div class="card-body-title">
                <span> {{ getTitle }}</span>
              </div>
              <div class="card-body-content">
                {{ $t('home.time') }} : {{ detail.startTime }} ~ {{ detail.endTime }}
                <span style="float: right; padding-right: 3em" v-if="!isMobile">{{ $t('home.organizer') }} : {{ detail.organizer }}</span>
                <div class="card-body-content-mobile" v-if="isMobile">{{ $t('home.organizer') }} : {{ detail.organizer }}</div>
              </div>
            </div>
          </el-card>
        </div>
        <div>
          <el-card :body-style="{ padding: '0px', background: '#1e1e1e'}">
            <div class="content">
              <div style="padding-top: 3vh" v-if="detail.img">
                <img :src="detail.img" alt="" style="object-fit: scale-down; width: 100%; height: 100%">
              </div>
              <span class="member">
                <el-divider content-position="left">{{ $t("home.organizer") }}</el-divider>
              </span>
              <member style="margin-top: 30px" :users="users"></member>
              <span class="member">
                <el-divider content-position="left">{{ $t("home.details") }}</el-divider>
              </span>
              <span v-html="markdown"></span>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <backtop></backtop>
  </div>
</template>

<script>
import events from '../assets/events.json'
import members from '../assets/members.json'
import MarkDownIt from 'markdown-it'
import backtop from '@/components/backtop.vue'
import member from '@/components/member.vue'

export default {
  data(){
    return{
      id: 0,
      eventList: [],
      detail: {},
      isMobile: false,
      users: [],
      memberList: [],
    }
  },
  components: {
    backtop,
    member
  },
  created() {
    this.id = this.$route.params.id;
    this.eventList = events;
    this.memberList = members;
    this.detail = this.eventList.filter(event => event.id === this.id)[0];
    this.getOrganizer();
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  beforeDestroy(){
    window.removeEventListener('resize', this.checkScreenSize);
  },
  methods: {
    checkScreenSize(){
      this.isMobile = window.innerWidth <= 768;
      if(this.isMobile){
        console.log(this.isMobile);
      }
    },
    getOrganizer(){
      if(Array.isArray(this.detail.organizer)){
        this.users = this.memberList.filter(member => this.detail.organizer.includes(member.name));
        this.detail.organizer = this.detail.organizer.join(" , ");
      }
      else {
        this.users = this.memberList.filter(member => this.detail.organizer.includes(member.name));
      }
    }
  },
  computed: {
    getTitle() { //语言切换标题
      return this.$store.state.langs === 'en' ? this.detail.titleEN : this.detail.titleZH;
    },
    markdown(){ //处理md和切换语言
      const md = new MarkDownIt();
      const en = md.render(this.detail.contentEN);
      const zh = md.render(this.detail.contentZH);
      return this.$store.state.langs === 'en' ? en : zh
    }
  },
}
</script>

<style scoped>
.el-card{
  border: #686868 1px solid;
  margin-bottom: 10px;
}

.card-body{
  padding-left: 15px;
}

.card-body-title{
  font-size: 22px;
  font-weight: bold;
  color: #aeadad;
  padding-top: 16px;
  padding-bottom: 20px;
  padding-left: 0.5em;
}

.card-body-content{
  font-size: 14px;
  color: #aeadad;
  padding-bottom: 20px;
  padding-left: 1em;
}

.card-body-content-mobile{
  font-size: 14px;
  color: #aeadad;
}

.content{
  font-size: 16px;
  color: #aeadad;
  padding: 1px 2em 15px;
}

.el-divider__text{
  background-color: #1e1e1e;
  color: #aeadad !important;
  font-size: 17px;
  font-weight: bold;
}
</style>