<template>
  <el-row>
    <el-menu router
             :default-active="this.$route.path"
             mode="horizontal"
             background-color="#1a1a1a"
             text-color="#ccc"
             active-text-color="#fff">
      <el-col span="12" style="display: flex; justify-content: flex-start; ">
        <div style="padding: 0; height: 56px; cursor: pointer" @click="goHome()">
          <img src="../assets/jackhouse.svg" alt="4Key Jack House" style="padding-top: 5.5px" v-if="!isMobile">
          <img src="../assets/jackhouse-mobile.svg" alt="4Key Jack House" style="padding-top: 5.5px" v-else>
        </div>
      </el-col>
      <el-col span="12" style="display: flex; justify-content: flex-end; " v-if="!isMobile">
        <el-menu-item index="/home">{{ $t('menu.home') }}</el-menu-item>
        <el-menu-item index="/search">{{ $t('menu.search') }}</el-menu-item>
        <el-menu-item index="/tournament">{{ $t('menu.tournament') }}</el-menu-item>
        <el-menu-item index="/about">{{ $t('menu.about') }}</el-menu-item>
        <lang></lang>
      </el-col>
      <el-col span="12" style="display: flex; justify-content: flex-end; " v-if="isMobile">
        <el-button icon="el-icon-menu" @click="drawerVisible = true"
                   style="background-color: #1a1a1a; color: #e1e1e1; border: none; height: 56px; font-size: 20px">
        </el-button>
      </el-col>
    </el-menu>

    <el-drawer :visible.sync="drawerVisible" direction="rtl" size="50%" style="color: #1a1a1a;">
      <el-menu router
               :default-active="this.$route.path"
               mode="vertical"
               background-color="#1a1a1a"
               text-color="#ccc"
               active-text-color="#fff">
        <el-menu-item index="/home" class="side-menu">{{ $t('menu.home') }}</el-menu-item>
        <el-menu-item index="/search" class="side-menu">{{ $t('menu.search') }}</el-menu-item>
        <el-menu-item index="/tournament" class="side-menu">{{ $t('menu.tournament') }}</el-menu-item>
        <el-menu-item index="/about" class="side-menu">{{ $t('menu.about') }}</el-menu-item>
        <lang class="drawer-lang"></lang>
      </el-menu>
    </el-drawer>

  </el-row>
</template>

<script>
import Lang from "@/components/lang.vue";
export default {
  name:'navMenu',
  components: {
    'lang': Lang
  },
  data(){
    return{
      isMobile: false, //手机适配菜单栏
      drawerVisible: false, //侧边栏的展开
    };
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  beforeDestroy(){
    window.removeEventListener('resize', this.checkScreenSize);
  },
  methods:{
    checkScreenSize(){
      this.isMobile = window.innerWidth <= 768;
      if(!this.isMobile){
        this.drawerVisible = false;
      }
    },
    goHome(){
      this.$router.push('/home');
    },
  },
};
</script>

<style scoped>
  .el-menu{
    border: none !important;
  }

  .el-dropdown-menu__item, .el-menu-item{
    padding: 0 30px;
  }

  .el-menu.el-menu--horizontal .el-menu-item.is-active {
    border-bottom: 2px solid #e1e1e1;
  }

  .side-menu.el-menu-item.is-active {
    border-right: 2px solid #e1e1e1 !important;
  }

  /deep/.el-drawer.rtl {
    background: #1a1a1a;
  }

  .drawer-lang{
    margin-top: 1vh;
  }


  /*  @media screen and (max-width: 768px) {
      .el-menu{
        display: none;
      }
    }

    @media screen and (min-width: 769px) {
      .el-icon-menu{
        display: none;
      }
    }*/
</style>